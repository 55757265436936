import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import { dt } from 'Shared/utils/dynamicRS'
import { t } from 'ttag'

import {
  FilterInputType,
  getDataByKey,
  getLocalizedSelectValue,
} from '../PuxRepeater'

const rootClass = `video-tutorial-filter`

const VideoTutorialFilter: FunctionComponent<FilterInputType> = (props) => {
  const data = props.completeData

  const rsSolution = dt(`Pux.RepeaterFilter.VideoTutorial.Solution`)
  const rsLevel = dt(`Pux.RepeaterFilter.VideoTutorial.Level`)
  const rsRole = dt(`Pux.RepeaterFilter.VideoTutorial.Role`)

  const cleanedSolutionData = getDataByKey(
    data,
    `videoTutorialSolution`,
    rsSolution
  )
  const cleanedLevelData = getDataByKey(data, `videoTutorialLevel`, rsLevel)
  const cleanedRoleData = getDataByKey(data, `videoTutorialRole`, rsRole)

  if (process.env.PROJECT === `EasyRedmineGatsby`) {
    props.setBindingTable({
      'filter-solution': {
        field: `videoTutorialSolution`,
      },
      'filter-difficulty': {
        field: `videoTutorialLevel`,
      },
    })
    return (
      <div className={`pux-repeater-filter ${rootClass}-wrapper`}>
        <div className={`pux-repeater-filter-item`}>
          <label htmlFor='filter-solution'>
            {t`Pux.RepeaterFilter.VideoTutorial.Solution.Label`}
          </label>
          <Select
            name='filter-solution'
            className='react-select-container'
            classNamePrefix='react-select'
            value={getLocalizedSelectValue(
              props.activeFilters[`videoTutorialSolution`],
              `Pux.RepeaterFilter.VideoTutorial.Solution`
            )}
            options={cleanedSolutionData}
            onChange={(selectedOption) =>
              props.updateUrl(selectedOption.value, `filter-solution`)
            }
          />
        </div>
        <div className={`pux-repeater-filter-item`}>
          <label htmlFor='filter-difficulty'>
            {t`Pux.RepeaterFilter.VideoTutorial.Level.Label`}
          </label>
          <Select
            name='filter-difficulty'
            className='react-select-container'
            classNamePrefix='react-select'
            value={getLocalizedSelectValue(
              props.activeFilters[`videoTutorialLevel`],
              `Pux.RepeaterFilter.VideoTutorial.Level`
            )}
            options={cleanedLevelData}
            onChange={(selectedOption) =>
              props.updateUrl(selectedOption.value, `filter-difficulty`)
            }
          />
        </div>
        {/* <input type='button' onClick={props.clearFilter} value='clear'></input> */}
      </div>
    )
  } else if (process.env.PROJECT === `EasyProjectGatsby`) {
    props.setBindingTable({
      'filter-role': {
        field: `videoTutorialRole`,
      },
    })
    return (
      <div className={`pux-repeater-filter ${rootClass}-wrapper`}>
        <div className={`pux-repeater-filter-item`}>
          <label htmlFor='filter-role'>
            {t`Pux.RepeaterFilter.VideoTutorial.Role.Label`}
          </label>
          <Select
            name='filter-role'
            className='react-select-container'
            classNamePrefix='react-select'
            value={getLocalizedSelectValue(
              props.activeFilters[`videoTutorialRole`],
              `Pux.RepeaterFilter.VideoTutorial.Role`
            )}
            options={cleanedRoleData}
            onChange={(selectedOption) =>
              props.updateUrl(selectedOption.value, `filter-role`)
            }
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default VideoTutorialFilter
